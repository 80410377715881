import React, { useEffect } from "react";
//import MainMenuController from "../js/MainMenuController";
import "@popperjs/core";

import "../../styles/main.scss";

import logo from "../../images/mamaai_logo--white.svg";
import chatGptApp from "../../images/products/large-language-models/chat-gpt-app.jpg";
import chatGptComputer from "../../images/products/large-language-models/chat-gpt-computer.jpg";
import languageModelBtn from "../../images/products/large-language-models/language-model-btn.jpg";

import Metadata from "../../js/Metadata";
import Navbar from "../../js/Navbar";
import Footer from "../../js/Footer";
import AiAcademyToast from "../../js/layout/ai_academy_toast";

import Swal from "sweetalert2";

const LargeLanguageModelsPage = () => {
    const contactFormUrl =
        "https://mlh0rbexm3.execute-api.us-east-2.amazonaws.com/default/ContactUsLog?TableName=ContactUsLogs";
    //const contactFormUrl = "https://httpbin.org/post"

    const description =
        "Large language models are AI systems that use Natural Language Processing (NLP) to understand and generate human-like text.";

    async function fetchUrl(url, data) {
        const response = await fetch(url, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "omit", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *folslow, error
            referrer: "client", // no-referrer, *client
            body: JSON.stringify(data), // body data type must match "Content-Type" header
        });
        await response.json();
        Swal.fire({
            title: "Thank you!",
            text: "Your message has been sent!",
            icon: "success",
            confirmButtonColor: "#FAA847",
        });
    }

    useEffect(() => {
        const handleDemoSubmit = (event) => {
            event.preventDefault();

            const contactFormData2 = {
                Item: {
                    topic: {
                        S: "Request Large Language Models Information",
                    },
                    email: {
                        S: document.getElementById("sender-email2").value,
                    },
                    message: {
                        S: document.getElementById("message-text2").value,
                    },
                },
                TableName: "ContactUsLogs",
            };

            fetchUrl(contactFormUrl, contactFormData2);
        };
        document
            .getElementById("submit-btn2")
            .addEventListener("click", handleDemoSubmit);
    }, []);

    return (
        <div className="container-max-width">
            <Metadata
                title="THE MAMA AI Large Language Models"
                url="https://themama.ai/products/large-language-models"
                description={description}
            />
            <Navbar />
            <section className="ai-logo-section section g-0 pb-5">
                <div>
                    <div id="home" className="section__anchor" />
                    <header className="text-center logo-header m-auto">
                        <a href="/#home">
                            <img
                                className="logo-header__logo d-block m-auto"
                                src={logo}
                                alt="The MAMA AI"
                            />
                        </a>
                        <h1 className="fs-3 pt-5 text--color-2">
                            Large Language Models
                        </h1>
                    </header>
                </div>
            </section>
            <section className="section pb-5 pt-5 section--light">
                <div className="container pt-2">
                    <div className="row justify-content-center">
                        <div className="col-xl-8">
                            <p className="body-3-text text-center">
                                Large language models (LLMs) are advanced
                                AI-powered systems that utilize Natural Language
                                Processing (NLP) to understand and generate
                                human-like text. They excel at interpreting
                                context, grammar, and patterns in human
                                language, making them ideal tools to improve
                                your applications.
                            </p>
                            <p className="pt-4 text-center body-3-text">
                                LLMs offer several benefits to businesses, such
                                as enhanced customer support, content
                                generation, data insights, language translation,
                                and personalization. Domain-specific models
                                provide greater precision and increased
                                relevance to cater to specific business needs.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-5 pt-5">
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="row featurette pt-lg-5 justify-content-center">
                                <div className="col-md-10 col-lg-6 col-xl-5 col-xxl-4 order-lg-2 align-self-center position-relative">
                                    <p className="body-3-text">
                                        On-premise deployment ensures data
                                        security, customization, compliance
                                        adherence, and decreased latency.
                                    </p>
                                </div>
                                <div className="align-self-center col-lg-5 col-xl-4 text-lg-start text-center">
                                    <img
                                        src={languageModelBtn}
                                        alt="LLM Large Language Model Button"
                                        className="img-fluid rounded translation-image"
                                    />
                                </div>
                            </div>
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-md-10 col-lg-6 col-xl-5 col-xxl-4 align-self-center position-relative">
                                    <p className="body-3-text">
                                        Combining LLMs with other AI-powered
                                        products improves precision and
                                        productivity in language-related tasks
                                        and can handle extensive data and
                                        sophisticated language structures.
                                    </p>
                                </div>
                                <div className="align-self-center col-lg-5 col-xl-4 text-lg-end text-center">
                                    <img
                                        src={chatGptComputer}
                                        alt="ChatGPT computer application"
                                        className="img-fluid rounded translation-image"
                                    />
                                </div>
                            </div>
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-md-10 col-lg-6 col-xl-5 col-xxl-4 align-self-center order-lg-2 position-relative">
                                    <p className="body-3-text">
                                        LLMs can be utilized in various
                                        industries, including healthcare, legal,
                                        e-commerce, media and publishing,
                                        customer support, and human resources.
                                    </p>
                                </div>
                                <div className="align-self-center col-lg-5 col-xl-4 order-lg-1 text-center text-lg-start">
                                    <img
                                        src={chatGptApp}
                                        alt="Chat Bot Mobile Application"
                                        className="img-fluid rounded translation-image"
                                    />
                                </div>
                            </div>
                            <div className="row featurette pt-5 justify-content-center">
                                <div className="col-md-10 col-xl-8">
                                    <p className="text-center body-3-text pt-4 text--color-2">
                                        At The MAMA AI, we offer customized AI
                                        solutions, including LLMs, semantic
                                        search engines, conversational
                                        platforms, and artificial voice
                                        synthesis. We help businesses enhance
                                        customer experience, increase efficiency
                                        and productivity in language-related
                                        tasks, and drive sustainable growth.
                                    </p>
                                    <p className="text-center body-3-text pt-4">
                                        Our streamlined AI consulting and
                                        development process includes requirement
                                        analysis, data collection and model
                                        training, integration and deployment,
                                        collaboration, and support.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section pb-5 pt-5 section--light contact-us-section">
                <div className="container px-8">
                    <div className="row justify-content-md-center">
                        <div className="col-12 col-lg-7 col-xxl-6 align-self-center">
                            <h2 className="pt-1">
                                Partnering with The MAMA AI ensures you can
                                confidently adopt bespoke AI solutions that
                                drive business growth and success.
                            </h2>
                            <p className="body-3-text pt-2 text-center text-lg-start">
                                Contact us today to learn more or schedule a
                                consultation with our experts.
                            </p>
                        </div>
                        <div className="col-12 col-lg-5 offset-xxl-1 col-xxl-4">
                            <form>
                                <h1
                                    className="modal-title pt-5 pt-lg-0"
                                    id="requestADemoLabel"
                                >
                                    Request information
                                </h1>
                                <div className="mb-3">
                                    <label
                                        htmlFor="sender-email2"
                                        className="col-form-label"
                                    >
                                        E-mail:
                                        <span className="form-asterisk">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="sender-email2"
                                        required
                                    />
                                    <label
                                        htmlFor="message-text2"
                                        className="col-form-label"
                                    >
                                        Message: (optional)
                                    </label>
                                    <textarea
                                        className="form-control"
                                        id="message-text2"
                                        rows="3"
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary text-white main-navbar__demo-button modal-button"
                                    id="submit-btn2"
                                >
                                    Send message
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <AiAcademyToast />
            <Footer />
        </div>
    );
};

export default LargeLanguageModelsPage;
